import React from 'react';
import { Dropdown } from '../../ui/Dropdown/Dropdown';

export const CountryPicker = ({ options, onChange, selectedCountry, title, withMargin, inspect, ...restProps }) => {
  const countries = options.map(item => {
    return {
      value: item.code,
      label: item.localName
    };
  });

  countries.sort((itemA, itemB) => (itemA.label > itemB.label ? 1 : -1));

  return (
    <Dropdown
      inspect={inspect}
      options={countries}
      preSelected={selectedCountry}
      title={title}
      onChange={selectedCountryId => {
        onChange(selectedCountryId);
      }}
      {...restProps}
      listFullWidth
      withMargin
    />
  );
};
