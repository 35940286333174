import gql from 'graphql-tag';
import { CountryListQuery } from '@deity/falcon-shop-data';

export const GET_COUNTRY_LIST = gql`
  query CountryList {
    countryList {
      items {
        englishName
        localName
        code
        regions {
          id
          code
          name
        }
      }
    }
  }
`;

export class CountryListQueryExt extends CountryListQuery {
  static defaultProps = {
    query: GET_COUNTRY_LIST
  };
}
