import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_ZIP_LIST = gql`
  query ZipList {
    zipList {
      id
      list {
        country
        pattern {
          example
          pattern
        }
      }
    }
  }
`;

export class ZipListQuery extends Query {
  static defaultProps = {
    query: GET_ZIP_LIST
  };
}
