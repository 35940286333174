export const STREET_REGEX = /.* .*/;
export const STREET_NUMBER_REGEX = /\d+/;
export const NAME_REGEX = /^[a-zA-ZÀ-ÿ'\s-]+$/;

export const streetValidator = ({ value }) => {
  if (!STREET_REGEX.test(value.trim())) {
    return { errorI18nId: 'missingHouseNr' };
  }
  return undefined;
};
export const streetNumberValidator = value => {
  if (!STREET_NUMBER_REGEX.test(value.trim())) {
    return true;
  }
  return false;
};

export const regionValidator = ({ value }) => {
  if (value === 0) {
    return { errorI18nId: 'missingRegion' };
  }
  return undefined;
};

export const fieldsValidator = (
  callback,
  values,
  zipList,
  t,
  storeConfiguration,
  usePackstation = false,
  defaultBilling = false
) => {
  const errors = {};
  const storeCode = storeConfiguration?.general?.country?.default;
  let ZIP_REGEX = null;
  // find country and related zip pattern and examples
  const countryZip = defaultBilling
    ? zipList.find(listItem => listItem.country === ((defaultBilling && defaultBilling.countryId) || storeCode))
    : zipList.find(listItem => listItem.country === values.countryId);
  const errorLabel = t('formError.zipError');
  const requiredPhone = storeConfiguration?.asam_customer?.address?.required_phonenumber_countries?.split(',');
  const minPassword = storeConfiguration?.customer?.password?.minimum_password_length;
  const minChars = storeConfiguration?.customer?.password?.required_character_classes_number;
  const requiredStates = storeConfiguration?.general?.region?.state_required;
  const trimName = values?.firstname?.trim();
  const trimLastName = values?.lastname?.trim();
  const trimPhone = values?.telephone?.trim();
  const trimCity = values?.city?.trim();
  const trimEmail = values?.email?.trim();
  const trimStreet = values?.street?.trim() || values?.street1?.trim();
  const trimCompany = values?.company?.trim();
  const pass = values?.password?.trim();
  const rePass = values?.confirmPassword?.trim();
  let counter = 0;
  const characterSets = minChars;

  if (trimName === '') {
    errors.firstname = t('formError.requiredFirstname');
  } else if (trimName.length > 30) {
    errors.firstname = t('formError.maxLength', {
      maxLength: '30'
    });
  } else if (!NAME_REGEX.test(trimName)) {
    errors.firstname = t('formError.charactersOnly');
  }

  if (trimLastName === '') {
    errors.lastname = t('formError.requiredLastname');
  } else if (trimLastName.length > 30) {
    errors.lastname = t('formError.maxLength', {
      maxLength: '30'
    });
  } else if (!NAME_REGEX.test(trimLastName)) {
    errors.lastname = t('formError.charactersOnly');
  }

  if (trimCompany !== undefined && trimCompany !== '' && trimCompany.length > 30) {
    errors.company = t('formError.maxLength', {
      maxLength: '30'
    });
  }
  if (requiredPhone && requiredPhone.includes(values.countryId) && trimPhone === '') {
    errors.telephone = t('formError.requiredPhone');
  }
  if (trimPhone !== undefined && trimPhone !== '' && !/^[+]{1}[ 0-9]+$|^[ 0-9]+$/.test(trimPhone)) {
    errors.telephone = t('formError.invalid', {
      label: t('signUp.telephone')
    });
  }
  if (trimPhone !== undefined && trimPhone !== '' && trimPhone.length > 30) {
    errors.telephone = t('formError.maxLength', {
      maxLength: '30'
    });
  }

  if (trimEmail) {
    if (trimEmail !== '' && trimEmail.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
      // Check user callback
      if (callback) {
        callback();
      }
      if (trimEmail.length > 80) {
        errors.email = t('formError.maxLength', {
          maxLength: '80'
        });
      }
    } else {
      errors.email = t('formError.invalid', {
        label: t('signUp.email')
      });
    }
  }

  if (pass) {
    if (pass.match(/\d+/)) {
      counter++;
    }
    if (pass.match(/[a-z]+/)) {
      counter++;
    }
    if (pass.match(/[A-Z]+/)) {
      counter++;
    }
    if (pass.match(/[^a-zA-Z0-9]+/)) {
      counter++;
    }
    if (pass.length < minPassword) {
      errors.password = t('formError.toShort', {
        min: minPassword
      });
    } else if (counter < characterSets) {
      errors.password = t('formError.notSafe', {
        min: minChars
      });
    } else if (pass.length > 250) {
      errors.password = t('formError.maxLength', {
        maxLength: '250'
      });
    }

    if (pass !== rePass) {
      errors.confirmPassword = t('formError.notEqual');
    }
  }

  if (!usePackstation) {
    if (trimStreet !== undefined && trimStreet === '') {
      errors.street = t('formError.required');
    } else if (trimStreet !== undefined && !STREET_REGEX.test(trimStreet)) {
      errors.street = t('formError.missingHouseNr');
    } else if (trimStreet !== undefined && trimStreet !== '' && trimStreet.length > 50) {
      errors.street = t('formError.maxLength', {
        maxLength: '50'
      });
    }

    if (trimCity !== undefined && trimCity === '') {
      errors.city = t('formError.requiredCity');
    }
    if (trimCity !== undefined && trimCity !== '' && trimCity.length > 30) {
      errors.city = t('formError.maxLength', {
        maxLength: '30'
      });
    }
    // check for pattern length to identify the regex pattern which should be applied
    if (countryZip?.pattern?.length === 1) {
      ZIP_REGEX = new RegExp(countryZip?.pattern?.[0]?.pattern);
      const { example } = countryZip?.pattern?.[0];
      // regex check and create error message with example
      if (!ZIP_REGEX.test(values.postcode.trim())) {
        errors.postcode = `${errorLabel}: ${example}`;
      }
    } else if (countryZip?.pattern?.length > 1) {
      // multiple pattern check for invalid matches
      const valid = countryZip?.pattern?.map(subPattern => {
        let invalid = 0;
        ZIP_REGEX = new RegExp(subPattern.pattern);
        if (ZIP_REGEX.test(values.postcode.trim())) {
          invalid++;
        }
        return invalid;
      });
      // create error message with multiple examples for invalid match
      if (!valid.includes(1)) {
        const examples = countryZip?.pattern?.map(subPattern => {
          return ` ${subPattern.example}`;
        });
        errors.postcode = `${t('formError.zipError')}: ${examples}.`;
      }
    }
  }

  // Validate terms
  if (values.terms !== undefined && !values.terms) {
    errors.terms = t('signUp.requiredField');
  }

  if (requiredStates.split(',').includes(values.countryId) && values.regionId === 0) {
    errors.regionId = t('formError.missingRegion');
  }

  return errors;
};

export const checkoutAddressValidator = (
  requiredPhone,
  setForceInvalid,
  storeConfiguration,
  values,
  isShipping,
  usePackstation,
  userLoggedIn,
  setCheckoutErrors,
  submit,
  submitPackstation,
  t,
  requiredStates,
  zipList
) => {
  const {
    email,
    prefix,
    firstname,
    lastname,
    street,
    postcode,
    company,
    city,
    countryId,
    regionId,
    telephone,
    password,
    repeatPassword,
    dhlAccount,
    dhlPostcode,
    dhlPackstation
  } = values;
  let formNotValid = true;
  const errors = {};
  const trimName = firstname.trim();
  const trimLastName = lastname.trim();
  const trimCity = city.trim();
  const trimCompany = company.trim();
  const trimPhone = telephone.trim();
  const minPassword = storeConfiguration?.customer?.password?.minimum_password_length;
  const minChars = storeConfiguration?.customer?.password?.required_character_classes_number;

  if (isShipping && usePackstation) {
    if (
      prefix !== '' &&
      firstname !== '' &&
      lastname !== '' &&
      dhlAccount !== '' &&
      dhlPostcode !== '' &&
      dhlPackstation !== null
    ) {
      formNotValid = false;
    }
  } else {
    if (prefix !== '' && firstname !== '' && lastname !== '' && street !== '' && postcode !== '' && city !== '') {
      formNotValid = false;
    }

    let ZIP_REGEX = null;
    // find country and related zip pattern and examples
    const countryZip = zipList.find(listItem => listItem.country === values.countryId);
    const errorLabel = t('formError.zipError');
    // check for pattern length to identify the regex pattern which should be applied
    if (countryZip?.pattern?.length === 1) {
      ZIP_REGEX = new RegExp(countryZip?.pattern?.[0]?.pattern);
      const { example } = countryZip?.pattern?.[0];
      // regex check and create error message with example
      if (!ZIP_REGEX.test(values.postcode.trim())) {
        formNotValid = true;
        errors.postcode = `${errorLabel}: ${example}`;
      }
    } else if (countryZip?.pattern?.length > 1) {
      // multiple pattern check for invalid matches
      const valid = countryZip?.pattern?.map(subPattern => {
        let invalid = 0;
        ZIP_REGEX = new RegExp(subPattern.pattern);
        if (ZIP_REGEX.test(values.postcode.trim())) {
          invalid++;
        }
        return invalid;
      });
      // create error message with multiple examples for invalid match
      if (!valid.includes(1)) {
        const examples = countryZip?.pattern?.map(subPattern => {
          return ` ${subPattern.example}`;
        });
        formNotValid = true;
        errors.postcode = `${t('formError.zipError')}: ${examples}.`;
      }
    }

    if (!STREET_REGEX.test(street.trim())) {
      formNotValid = true;
      errors.street = t('formError.missingHouseNr');
    }

    if (requiredStates.split(',').includes(countryId) && regionId === 0) {
      formNotValid = true;
      errors.regionId = t('formError.missingHouseNr');
    }

    if (trimCity === '') {
      formNotValid = true;
      errors.city = t('formError.requiredCity');
    }

    if (trimCity !== '' && trimCity.length > 30) {
      formNotValid = true;
      errors.city = t('formError.maxLength', {
        maxLength: '30'
      });
    }
  }

  if (trimName === '') {
    formNotValid = true;
    errors.firstname = t('formError.requiredFirstname');
  } else if (trimName.length > 30) {
    formNotValid = true;
    errors.firstname = t('formError.maxLength', {
      maxLength: '30'
    });
  } else if (!NAME_REGEX.test(trimName)) {
    formNotValid = true;
    errors.firstname = t('formError.charactersOnly');
  }

  if (trimLastName === '') {
    formNotValid = true;
    errors.lastname = t('formError.requiredLastname');
  } else if (trimLastName.length > 30) {
    formNotValid = true;
    errors.lastname = t('formError.maxLength', {
      maxLength: '30'
    });
  } else if (!NAME_REGEX.test(trimLastName)) {
    formNotValid = true;
    errors.lastname = t('formError.charactersOnly');
  }

  if (trimCompany !== '' && trimCompany.length > 30) {
    formNotValid = true;
    errors.company = t('formError.maxLength', {
      maxLength: '30'
    });
  }

  if (
    (isShipping &&
      requiredPhone &&
      requiredPhone.includes(countryId) &&
      trimPhone === '' &&
      prefix !== '' &&
      firstname !== '' &&
      lastname !== '' &&
      street !== '' &&
      postcode !== '' &&
      city !== '' &&
      email !== '') ||
    (!isShipping &&
      requiredPhone &&
      requiredPhone.includes(countryId) &&
      trimPhone === '' &&
      prefix !== '' &&
      firstname !== '' &&
      lastname !== '' &&
      street !== '' &&
      postcode !== '' &&
      city !== '')
  ) {
    formNotValid = true;
    errors.telephone = t('formError.requiredPhone');
    setForceInvalid(true);
  } else if (requiredPhone && requiredPhone.includes(countryId) && trimPhone === '') {
    formNotValid = true;
    errors.telephone = t('formError.requiredPhone');
  }

  if (trimPhone !== '' && !/^[+]{1}[ 0-9]+$|^[ 0-9]+$/.test(trimPhone)) {
    formNotValid = true;
    errors.telephone = t('formError.invalid', {
      label: t('signUp.telephone')
    });
  }

  if (trimPhone !== '' && trimPhone.length > 30) {
    formNotValid = true;
    errors.telephone = t('formError.maxLength', {
      maxLength: '30'
    });
  }

  if (isShipping && !userLoggedIn) {
    const pass = password.trim();
    const rePass = repeatPassword.trim();
    let counter = 0;
    if (pass.match(/\d+/)) {
      counter++;
    }
    if (pass.match(/[a-z]+/)) {
      counter++;
    }
    if (pass.match(/[A-Z]+/)) {
      counter++;
    }
    if (pass.match(/[^a-zA-Z0-9]+/)) {
      counter++;
    }
    if (pass.length < minPassword) {
      formNotValid = true;
      errors.password = t('formError.toShort', {
        min: minPassword
      });
    } else if (counter < minChars) {
      formNotValid = true;
      errors.password = t('formError.notSafe', {
        min: minChars
      });
    } else if (pass.length > 250) {
      formNotValid = true;
      errors.password = t('formError.maxLength', {
        maxLength: '250'
      });
    }
    if (pass !== rePass) {
      formNotValid = true;
      errors.repeatPassword = t('formError.notEqual');
    }

    if (rePass !== '') {
      if (pass !== rePass) {
        formNotValid = true;
        errors.repeatPassword = t('formError.notEqual');
      }
    }
  }

  if (email && email !== '' && /.+@.+\..{2,}$/.test(email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = t('formError.invalid', {
      label: t('signUp.email')
    });
  }

  if (isShipping && !userLoggedIn && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    formNotValid = true;
  }

  setCheckoutErrors(formNotValid);

  if (!formNotValid) {
    if (usePackstation && isShipping) {
      submitPackstation(values);
    } else {
      submit(values);
    }
  }

  return errors;
};
